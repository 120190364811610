<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0" v-if="!isLoading && showCard == true">
        <b-link class="brand-logo">
          <vuexy-logo />
        </b-link>

        <b-card-title class="mb-1 text-center">
          <h3>Payment Failed</h3>
        </b-card-title>
        <!--div>
          <b-row>
            <div class="col-12"><strong>Bill Period :</strong> {{invoice.bill_period ? invoice.bill_period : '-testing-'}}</div>
          </b-row>
          <b-row>
            <div class="col-12"><strong>No Invoice :</strong> {{invoice.invoice_no ? invoice.invoice_no : '-testing-'}}</div>
          </b-row>
          <b-row>
            <div class="col-12"><strong>Total :</strong>IDR {{invoice.total_amount_due ? formatPrice(invoice.total_amount_due) : '0'}}</div>
          </b-row>
        </div-->
        <!--b-table :items="[invoice]" responsive :fields="fields" class="mb-0"></b-table-->
        <!-- media -->
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-primary" size="34">
              <feather-icon icon="ClipboardIcon" size="18" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-0">
              Bill Period :
            </h6>
            <small>{{ invoice.bill_period }}</small>
          </b-media-body>
        </b-media>

        <b-media no-body style="margin-top:5px;margin-bottom:5px;">
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-primary" size="34">
              <feather-icon icon="FileIcon" size="18" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-0">
              No Invoice :
            </h6>
            <small>{{ invoice.invoice_no }}</small>
          </b-media-body>
        </b-media>
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-primary" size="34">
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-0">
              Total :
            </h6>
            <small>IDR {{ formatPrice(invoice.total_amount_due) }}</small>
          </b-media-body>
        </b-media>
        <br />
        <b-card-text class="mb-2 text-center">
          Your payment was Failed
        </b-card-text>
        <b-button
          class="text-center"
          style="float: left"
          size="md"
          block
          variant="danger"
          @click="toDashboard"
          >Return to Dashboard</b-button
        >
      </b-card>

      <b-card v-if="isLoading == true">
        <div>
          <loading></loading>
        </div>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
  BTable,
  BLink,
  BBadge,
  BCardTitle,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import Loading from "@core/components/loading/Loading.vue";
import Ripple from "vue-ripple-directive";
import Transfer from "./Transfer.vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";

export default {
  components: {
    BCardTitle,
    VuexyLogo,
    BForm,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    ToastificationContent,
    Loading,
    BTable,
    Ripple,
    BLink,
    BBadge,
    Transfer,
    BMedia,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      showCard: false,
      invoice: [],
    };
  },
  props: {
    SESSIONID: {
      type: String,
      default: () => "",
    },
  },
  methods: {
    init: function() {
      this.isLoading = true;
      if (this.$route.query.order_id) {
        // this.checkValidOrderID(order_id);
        this.getInfo();
      } else {
        this.toDashboard();
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    checkValidOrderID: function(order_id) {},
    getInfo: function() {
      this.isLoading = true;
      axios
        .get("payment/info-payment/" + this.$route.query.order_id)
        .then((response) => {
          if (response.data.status == 2000) {
            this.showCard = true;
            this.invoice = response.data.invoices;
          } else {
            this.$router.push({ name: "dashboard" });
          }
        })
        .catch((error) => {
          if (error.response.data.message == "Expired token") {
            localStorage.clear();
            this.$router.push({ name: "login" });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Payment",
                icon: "EditIcon",
                variant: "success",
                text: "Session Expired, Please Login Again",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Payment",
                icon: "EditIcon",
                variant: "success",
                text: error.response.data.message,
              },
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    toDashboard() {
      this.$router.push({ name: "dashboard" });
    },
    fetchData() {
      this.error = this.post = null;
      this.loading = true;
      const fetchedId = this.$route.params.SESSIONID;
      //console.log(fetchedId);
      //console.log(this.$route.params);

      // replace `getPost` with your data fetching util / API wrapper
      getPost(fetchedId, (err, post) => {
        // make sure this request is the last one we did, discard otherwise
        if (this.$route.params.id !== fetchedId) return;
        this.loading = false;
        if (err) {
          this.error = err.toString();
        } else {
          this.post = post;
        }
      });
    },
  },
  mounted() {
    //console.log(this.$route.query.order_id);
    this.init();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
